export default [
  // Menu Home
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  // Menu Clients
  {
    title: 'Clientes',
    route: 'clients',
    icon: 'UserIcon',
  },
  // Menu Invoices
  {
    title: 'Faturas',
    route: 'invoices',
    icon: 'FileTextIcon',
  },
  // Menu Plans
  {
    title: 'Planos',
    route: 'plans',
    icon: 'FeatherIcon',
  },
  // Menu Carts
  {
    title: 'Cartas',
    route: 'cards',
    icon: 'MailIcon',
  },
  // Menu Titles
  // {
  //   title: 'Títulos',
  //   route: 'titles',
  //   icon: 'BookmarkIcon',
  // },
  // Menu Action
  {
    title: 'Histórico de Ações',
    route: 'action',
    icon: 'TrendingUpIcon',
  },
  // Menu terms
  {
    title: 'Termos',
    route: 'terms',
    icon: 'ThumbsUpIcon',
  },
  // Menu Collaborator
  // {
  //   title: 'Envios por Colaborador',
  //   route: 'collaborator',
  //   icon: 'SendIcon',
  // },
  // Menu Support
  // {
  //   title: 'Negociação',
  //   route: 'NegotiationTitles',
  //   icon: 'DollarSignIcon',
  // },
  // Menu tickets
  // {
  //   title: 'Parcelamentos',
  //   route: 'installment',
  //   icon: 'CreditCardIcon',
  // },
  // Menu feedback
  // {
  //   title: 'Serasa',
  //   route: 'serasa',
  //   icon: 'UserXIcon',
  // },


  // Menu Relatório
  // {
  //   title: 'Relatório',
  //   icon: 'ClipboardIcon',
  //   tag: '3',
  //   tagVariant: 'light-primary',
  //   children: [
  //     // Menu Action
  //     {
  //       title: 'Histórico de ações',
  //       route: 'action',
  //       icon: 'TrendingUpIcon',
  //     },
  //     // Menu Collaborator
  //     {
  //       title: 'Envios por colaborador',
  //       route: 'collaborator',
  //       icon: 'SendIcon',
  //     },
  //   ],
  // },
  // // Menu Modules
  // {
  //   title: 'Módulos',
  //   icon: 'DownloadCloudIcon',
  //   tag: '3',
  //   tagVariant: 'light-primary',
  //   children: [
  //     // Menu Support
  //     {
  //       title: 'Negociação',
  //       route: 'NegotiationTitles',
  //       icon: 'DollarSignIcon',
  //     },
  //     // Menu tickets
  //     {
  //       title: 'Parcelamentos',
  //       route: 'installment',
  //       icon: 'CreditCardIcon',
  //     },
  //     // Menu feedback
  //     {
  //       title: 'Serasa',
  //       route: 'serasa',
  //       icon: 'UserXIcon',
  //     },
  //   ],
  // },
]
